import { Component, Fragment } from 'react';

import { IconHelper } from '../../../utils/icon-helper';
import { HtmlEntityTypes } from '../../../utils/icon.utils';
import Tooltip from '../tooltip/tooltip.component';
import style from './dropdown.shared.style.module.scss';

export interface DropdownProps<T> {
    children?: any;
    values: T[];
    valueFormatter: (value: T) => string;
    hasTooltip: (value: T) => boolean;
    getTooltip: (value: T) => string;
    selectedValueFormatter?: (value: T) => string;
    initialValue?: T | undefined;
    placeholder?: string;
    label?: string;
    btnClassNames?: string;
    btnGroupClassNames?: string;
    firstBtnStyles?: any;
    onSelect?: (selectedValue: T, forced?: boolean) => void
    canSelect?: (selectedValue: T) => Promise<boolean>
    shownFn?: (value: T) => boolean;
    emitInitialValueOnInit?: boolean;
}

interface DropdownState<T> {
    values: T[];
    selectedValue?: T | undefined;
    shown?: boolean;
}

export default class Dropdown<T> extends Component<DropdownProps<T>, DropdownState<T>> {
    wrapperRef: any;
    btnClassNames = 'x-btn x-btn-gray x-btn-gray-outline';
    firstBtnStyles = {};

    readonly state: Readonly<DropdownState<T>> = {
        selectedValue: this.props.initialValue,
        values: this.props.values,
        shown: false,
    };
    constructor(props: DropdownProps<T>) {
        super(props);
        this.firstBtnStyles = this.props.firstBtnStyles;
        this.handleClickOutside = this.handleClickOutside.bind(this);

    }

    componentDidMount() {
        if (this.props.emitInitialValueOnInit) {
            this.updateSelectedValue(this.props.initialValue);
        }
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    handleClickOutside(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.toggleDropdown();
        }
    }

    toggleDropdown() {
        if (!this.state.shown) {
            document.addEventListener('mousedown', this.handleClickOutside, { passive: true });
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
        this.setState({
            shown: !this.state.shown,
        });
    }

    async userTapedOnDropdownItem(selectedValue: T) {
        if (this.props.canSelect) {
            if (!await this.props.canSelect(selectedValue)) {
                return;
            }
        }
        this.updateSelectedValue(selectedValue);
        this.toggleDropdown();
    }

    updateSelectedValue(selectedValue: T, forced = false) {
        this.setState({
            selectedValue: selectedValue,
        });
        if (this.props.onSelect) {
            this.props.onSelect(selectedValue, forced);
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.valueFilter(this.state.selectedValue)) {
            this.updateSelectedValue(this.props.values.find((v) => this.valueFilter(v)), true);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            values: nextProps.values,
        });
        if (!nextProps.values.some(item => item === this.state.selectedValue)) {
            nextProps.values.forEach(item => {
                item.selected = false;
            });
            if (nextProps.initialValue) {
                nextProps.initialValue.selected = true;
            }
            this.setState({
                selectedValue: nextProps.initialValue,
            });
        }
    }

    private valueFilter(value: T) {
        return !this.props.shownFn || this.props.shownFn(value);
    }

    render(): JSX.Element {
        const getSelectedValue = () => {
            if (this.props.selectedValueFormatter) {
                return this.props.selectedValueFormatter(this.state.selectedValue)
            }
            if (this.props.valueFormatter) {
                return this.props.valueFormatter(this.state.selectedValue)
            }
            return this.props.placeholder || 'Not Selected'
        }
        return (
            <div ref={(node) => this.setWrapperRef(node)} className={`${style.dropdown} ${this.props.btnGroupClassNames || ''}`}>
                {
                    <div key="dropdownTrigger" className={`x-btn-group ${style['dropdown-btn-group']}`} onClick={() => this.toggleDropdown()}>
                        {
                            this.props.label && <button type="button" className={`${this.btnClassNames} ${style['label']}`} disabled>{this.props.label}
                            </button>
                        }
                        <button type="button" className={`${this.btnClassNames} ${style['value']}`} style={this.firstBtnStyles}>
                            {getSelectedValue()}
                        </button>
                        <button type="button" className={`${this.btnClassNames} ${style['caret']}`}>&#9660;</button>
                    </div>
                }
                <ul key="dropdownMenu" className={`${style["dropdown-content"]} ${this.state.shown ? style.show : ''}`}>
                    {
                        this.state.values.filter(val => this.valueFilter(val)).map((val, index) => {
                            return (
                                <li key={`${index}`} className={`${this.props.valueFormatter(val) === this.props.valueFormatter(this.state.selectedValue) ? style.active : ''}`}
                                    onClick={() => this.userTapedOnDropdownItem(val)}>{this.props.valueFormatter(val)}
                                    <span className={style.tooltip}>
                                        {
                                            this.props.hasTooltip(val) &&
                                            <Tooltip text={this.props.getTooltip(val)}>
                                                {
                                                    IconHelper.getIconElement(HtmlEntityTypes.INFO_WITH_CIRCLE)
                                                }
                                            </Tooltip>
                                        }
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }
}